<template>
  <div class="elv-valuation-price-detail-content">
    <div class="elv-valuation-price-detail-header">
      <span class="elv-valuation-price-detail-header-top"
        >{{ t('valuation.price.totalPriceProvider') }}:
        <span class="elv-valuation-price-detail-header-number">{{ showPageTableInfo.total }}</span></span
      >
      <elv-button style="margin-left: 16px" height="32" width="120" round plain type="primary" @click="addNewPrice">
        <SvgIcon name="source-add" width="16" height="16" />{{ t('button.addNew') }}</elv-button
      >
    </div>
    <PriceTable
      :tableData="tableList"
      :tableLoading="tableLoading"
      :pageParams="showPageTableInfo"
      @onClickTableCell="onClickTableCell"
      @onChangePage="onChangePage"
      @onChangePageSize="onChangePageSize"
    />
  </div>
  <AddPriceDialog ref="addPriceRef" @onClickConfirmHandler="onRefreshPriceProviderList" />
  <PriceDetailOverlay ref="priceDetailRef" :currentData="detailShowInfo" />
</template>
<script lang="ts" setup>
import PriceTable from './components/TableList.vue'
import { useEntityStore } from '@/stores/modules/entity'
import AddPriceDialog from './components/AddPriceDialog.vue'
import { useValuationStore } from '@/stores/modules/valuation'
import PriceDetailOverlay from './components/PriceDetailOverlay.vue'

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const valuationStore = useValuationStore()

const addPriceRef = ref()
const priceDetailRef = ref()
const detailShowInfo = ref({})
const tableLoading = ref(false)
const currentEditorData = ref<any>({})
const showPageTableInfo = ref({
  page: 1,
  limit: 20,
  total: 0
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const tableList = computed(() => {
  return valuationStore.priceProviderList.list || []
})

/**
 * 添加价格源，打开价格源弹窗
 */
const addNewPrice = () => {
  currentEditorData.value = null
  addPriceRef.value.changeDialogStatus()
}

/**
 * 获取价格源数据列表
 */
const getPriceTableList = async () => {
  tableLoading.value = true
  try {
    const respondData = await valuationStore.fetchPriceProviderList(entityId.value, {
      page: showPageTableInfo.value.page,
      limit: showPageTableInfo.value.limit
    })
    showPageTableInfo.value.total = respondData.totalCount
  } catch (error) {
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

/**
 * 打开价格源详情界面
 * @param data 单条价格源数据
 */
const onClickTableCell = (data: any) => {
  detailShowInfo.value = data
  priceDetailRef.value?.onCheckDrawerStatus()
}

/**
 * 添加价格源确认事件回调
 */
const onRefreshPriceProviderList = () => {
  addPriceRef.value.changeDialogStatus()
  showPageTableInfo.value.page = 1
  getPriceTableList()
}

/**
 * 分页切换页码
 * @param pageNum 页码
 */
const onChangePage = (pageNum: number) => {
  showPageTableInfo.value.page = pageNum
  getPriceTableList()
}

/**
 * 分页切换每页显示数量
 * @param pageSize 每页显示数量
 */
const onChangePageSize = (pageSize: number) => {
  showPageTableInfo.value.limit = pageSize
  getPriceTableList()
}

watch(
  [() => route, () => entityStore.entityDetail?.entityId],
  async () => {
    try {
      if (route.name === 'entity-valuation-price') {
        await getPriceTableList()
      }
    } catch (error) {
      console.log(error)
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  valuationStore.clearPriceProviderList()
})
</script>
<style lang="scss" scoped>
.elv-valuation-price-detail-content {
  flex: 1;
  min-height: 0;
  position: relative;

  .elv-valuation-price-detail-header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    .elv-valuation-price-detail-header-top {
      color: #838d95;
      font-size: 12px;

      .elv-valuation-price-detail-header-number {
        color: #0e0f11;
        margin-left: 5px;
      }
    }
  }
}
</style>
