<template>
  <div class="elv-cell-date-time-main-price">
    <div class="elv-cell-date-time-main-top">
      {{
        dayjs(props.fromTime)
          .tz(entityStore.entityDetail?.timezone ?? 'UTC')
          .format(props.format)
      }}<span v-if="props.endTime"
        >-
        {{
          dayjs(props.endTime)
            .tz(entityStore.entityDetail?.timezone ?? 'UTC')
            .format(props.format)
        }}</span
      >
    </div>
  </div>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { useEntityStore } from '@/stores/modules/entity'

dayjs.extend(utc)
dayjs.extend(timezone)
const entityStore = useEntityStore()

const props = defineProps({
  fromTime: {
    type: String,
    default: '',
    require: true
  },
  endTime: {
    type: String,
    default: ''
  },
  format: {
    type: String,
    default: 'YYYY/MM/DD'
  }
})
</script>
<style lang="scss">
.elv-cell-date-time-main-price {
  .elv-cell-date-time-main-top {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #0e0f11;
  }
}
</style>
